<template>
  <div class="mainNavigation" v-bind:class="{ stickyNav: isSticky }">
    <div class="container">
      <nav class="navbar">
        <div class="brand">
          <h1 class="logo">
            <router-link to="/"> Prof<span>.</span> </router-link>
          </h1>
        </div>
        <div class="maiMenuContent" v-bind:class="{ openMenuItems: isActive }">
          <ul>
            <li class="home activeLink">
              <a href="#">Home</a>
            </li>
            <li class="about">
              <a href="#about">About</a>
            </li>
            <li class="skills">
              <a href="#skills">Skills</a>
            </li>
            <li class="experience">
              <a href="#experience">Experience</a>
            </li>
            <li class="portfolio">
              <a href="#portfolio">Portfolio</a>
            </li>
            <li class="contact">
              <a href="#contact">Contact</a>
            </li>
          </ul>
        </div>
        <div class="sideNavOptions">
          <ul>
            <li class="changeThemeIcon">
              <div class="toggleSwithContainer">
                <label class="switch">
                  <input type="checkbox" v-on:change="toggleTheme()"/>
                  <span class="slider">
                    <span class="moonIcon">
                      <font-awesome-icon :icon="['fas', 'moon']" />
                    </span>
                    <span class="sunIcon">
                      <font-awesome-icon :icon="['fas', 'sun']" />
                    </span>
                  </span>
                </label>
              </div>
            </li>
            <li class="hamBurger">
              <button
                v-on:click="toggleMenu"
                v-bind:class="{ openMenu: isActive }"
                class="hamBurgerBtn"
              >
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </button>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</template>
<script>
export default {
  name: "navigations",
  data() {
    return {
      isActive: false,
      isSticky: false,
      scrollPosition: 0,
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  methods: {
    toggleMenu() {
      this.isActive = !this.isActive;
    },
    handleScroll() {
      this.scrollPosition = window.scrollY;
      if (this.scrollPosition >= 100) {
        this.isSticky = true;
      } else {
        this.isSticky = false;
      }
    },
    toggleTheme(){
      document.querySelector("body").classList.toggle("dayTheme");
      document.querySelector("body").classList.toggle("nightTheme");
    }
  },
};
</script>