<template>
  <div id="app">
    <navigation />
    <hero />
    <div class="container">
      <experienceBoxes />
      <about />
      <skills />
      <experience />
      <portfolio />
      <contact />
    </div>
    <bottomBar />
    <bottomToTopBtn/>
  </div>
</template>

<script>
// @ is an alias to /src
import navigation from "@/components/navigation.vue";
import hero from "@/components/hero.vue";
import experienceBoxes from "@/components/experienceBoxes.vue";
import about from "@/components/about.vue";
import skills from "@/components/skills.vue";
import experience from "@/components/experience.vue";
import portfolio from "@/components/portfolio.vue";
import contact from "@/components/contact.vue";
import bottomBar from "@/components/bottomBar.vue";
import bottomToTopBtn from "@/components/bottomToTop.vue";
export default {
  name: "Home",
  components: {
    navigation,
    hero,
    experienceBoxes,
    about,
    skills,
    experience,
    portfolio,
    contact,
    bottomBar,
    bottomToTopBtn,
  },
};
</script>