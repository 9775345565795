<template>
  <div class="experiencesBoxes">
    <div class="row">
      <div
        class="col-lg-4 mt-2 mb-2"
        data-aos="zoom-in"
        data-aos-duration="500"
      >
        <div class="expBox">
          <a href="#">
            <div class="exIcon">
              <img src="@/assets/img/emblem.png" alt="" />
            </div>
            <div class="exTxt">
              <h3>4+</h3>
              <strong> Years of <br/> Experience </strong>
            </div>
          </a>
        </div>
      </div>
      <div
        class="col-lg-4 mt-2 mb-2"
        data-aos="zoom-in"
        data-aos-duration="700"
      >
        <div class="expBox">
          <a href="#">
            <div class="exIcon">
              <img src="@/assets/img/check-mark.png" alt="" />
            </div>
            <div class="exTxt">
              <h3>200+</h3>
              <strong>Completed <br/> Projects</strong>
            </div>
          </a>
        </div>
      </div>
      <div
        class="col-lg-4 mt-2 mb-2"
        data-aos="zoom-in"
        data-aos-duration="900"
      >
        <div class="expBox">
          <a href="#">
            <div class="exIcon">
              <img src="@/assets/img/happy.png" alt="" />
            </div>
            <div class="exTxt">
              <h3>95+</h3>
              <strong> Happy <br/> Clients </strong>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "experienceBoxes",
};
</script>