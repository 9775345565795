<template>
  <div id="mainContainer" class="white">
    <customCursor
      :targets="['a', 'button', 'img', 'hovercursor', 'span', 'li']"
      :circleColor="'#ff2a1a'"
      :circleColorHover="'rgba(255, 41, 26, 0.3)'"
      :dotColor="'#ff2a1a'"
      :dotColorHover="'rgba(255, 41, 26, 0.3)'"
      :hoverSize="1.8"
    ></customCursor>
    <router-view></router-view>
  </div>
</template>
<script>
import customCursor from "./components/custCursor.vue";
export default {
  name: "MainComponent",
  components: {
    customCursor,
  },
  mounted() {
    const sections = document.querySelectorAll("section");
    const navLi = document.querySelectorAll(".maiMenuContent ul li");
    var profBody = document.body,
      profHtml = document.documentElement;

    window.onscroll = () => {
      var bodyHeight = Math.max(
        profBody.scrollHeight,
        profBody.offsetHeight,
        profHtml.clientHeight,
        profHtml.scrollHeight,
        profHtml.offsetHeight
      );
      var currentScrollPosition = window.pageYOffset;
      var secToTopDistance = bodyHeight - currentScrollPosition;
      secToTopDistance = (secToTopDistance * 300) / bodyHeight;
      if (secToTopDistance > 55) {
        document.getElementById("roundPath").style.strokeDashoffset =
          secToTopDistance + "%";
      } else {
        document.getElementById("roundPath").style.strokeDashoffset = "0%";
      }
      var current = "";
      sections.forEach((section) => {
        const sectionTop = section.offsetTop;

        if (pageYOffset >= sectionTop - 400) {
          current = section.getAttribute("id");
        }
      });

      navLi.forEach((li) => {
        li.classList.remove("activeLink");
        if (li.classList.contains(current)) {
          li.classList.add("activeLink");
        }
      });
    };
  },
};
</script>