<template>
  <div class="bottomToTopBtnContainer">
    <button
      class="bottomToTop"
      v-on:click="topFunction()"
      v-bind:class="{ showBottomTopBtn: showBottomToTopBtn }"
    >
      <span class="roundBtnSvg">
        <svg width="100%" height="100%" viewBox="-1 -1 102 102">
          <path id="roundPath"
            d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
            style="stroke-dashoffset: 0; stroke-dasharray: 307.919"
          ></path>
        </svg>
      </span>
      <font-awesome-icon :icon="['fas', 'angle-up']" />
    </button>
  </div>
</template>

<script>
export default {
  name: "bottomToTop",
  data() {
    return {
      isActive: false,
      showBottomToTopBtn: false,
      scrollPosition: 0,
    };
  },
  created() {
    window.addEventListener("scroll", this.showTopBtn);
  },
  destroyed() {
    window.removeEventListener("scroll", this.showTopBtn);
  },
  methods: {
    showTopBtn() {
      this.scrollPosition = window.scrollY;
      if (this.scrollPosition >= 250) {
        this.showBottomToTopBtn = true;
      } else {
        this.showBottomToTopBtn = false;
      }
    },
    topFunction() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>
<style lang="css">
.bottomToTop {
  position: fixed;
  z-index: -1;
  bottom: 20px;
  font-size: 25px;
  right: 20px;
  color: #f42a1b;
  background: transparent;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  border: 3px solid rgba(244, 42, 27, 0.361);
  justify-content: center;
  transform: scale(0);
  outline: none !important;
  border-radius: 100%;
  transition: all linear 0.3s;
}
.bottomToTop.showBottomTopBtn {
  transform: scale(1);
  z-index: 5555;
}

.roundBtnSvg svg {
  position: absolute;
  top: 50%;
  border-radius: 100%;
  left: 50%;
  width: 50px;
  height: 50px;
  transition: all linear .3s;
  transform: translate(-50%, -50%);
}
.roundBtnSvg svg path {
  fill: none;
  stroke-width: 5px;
  stroke: #f42a1b;
}
</style>