<template>
  <section id="home">
    <div class="heroSection">
      <div class="paralexMouseMoveContainer">
        <kinesis-container>
          <kinesis-element :strength="40" class="dontMovOnMob">
            <div class="heroImgLayer1">
              <img src="@/assets/img/hero.png" class="heroImg" alt="user Img" />
            </div>
            <div class="heroImgLayer2">
              <img
                src="@/assets/img/hero.png"
                class="heroImg2"
                alt="user Img"
              />
            </div>
          </kinesis-element>
        </kinesis-container>
      </div>
      <div class="mobileCenterContent">
        <h1 class="heroTitle">My Name is Saad</h1>
        <div class="animatedIntro content">
          <div class="content__container">
            <p class="content__container__text">I'm Web</p>
            <ul class="content__container__list">
              <li class="content__container__list__item">Developer</li>
              <li class="content__container__list__item">Designer</li>
              <li class="content__container__list__item">Engineer</li>
              <li class="content__container__list__item">Master</li>
            </ul>
          </div>
        </div>
        <div class="heroSocialIcons">
          <ul>
            <li>
              <a
                target="_blank"
                href="https://www.facebook.com/saadullah.sajid.338/"
              >
                <font-awesome-icon :icon="['fab', 'facebook']" />
                <span>Facebook</span>
              </a>
            </li>
            <li>
              <a target="_blank" href="https://www.instagram.com/saad.s2809">
                <font-awesome-icon :icon="['fab', 'instagram']" />
                <span>Instagram</span>
              </a>
            </li>

            <li>
              <a
                target="_blank"
                href="https://pk.linkedin.com/in/saad-ullah-sajid-301173166"
              >
                <font-awesome-icon :icon="['fab', 'linkedin']" />
                <span>Linkedin</span>
              </a>
            </li>
            <li>
              <a target="_blank" href="https://github.com/saadullahsajid">
                <font-awesome-icon :icon="['fab', 'github']" />
                <span>Github</span>
              </a>
            </li>
          </ul>
          <div class="socialBottomLine"></div>
        </div>
      </div>
      <div class="scrollBtn">
        <button   v-on:click="scrollDown()">Scroll</button>
        <div class="socialBottomLine"></div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "hero",
  data() {
    return {};
  },

  methods: {
    scrollDown() {
      window.scrollBy(0, 400);
    },
  },
};
</script>
<style lang="css">
.heroImgLayer1 {
  -webkit-mask-image: url("../assets/img/splash.png");
  mask-image: url("../assets/img/splash.png");
}
</style>
