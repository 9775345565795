<template>
  <section id="experience">
    <div class="mainExperienceContainer">
      <div class="row align-items-center">
        <div class="col-lg-6 order-lg-0 order-1">
          <div class="expereinceTimeLineArea">
            <div class="timepath">
              <span class="line"></span>
              <span class="semicircle"></span>
              <span class="line"></span>
              <span class="semicircle"></span>
              <span class="line"></span>
            </div>
            <div
              class="custToolTipContainer"
              v-bind:class="{ toolTipActive: activeToolTip }"
            >
              <button class="closToolTip" v-on:click="closeToolTip">
                <font-awesome-icon :icon="['fas', 'times']" />
              </button>
              <h2 class="componyTitle">{{ componyTitle }}</h2>
              <h3 class="designation">{{ designation }}</h3>
              <p class="jobInfo">
                {{ jobInfo }}
              </p>
            </div>
            <ul class="timeLineItemsList">
              <li
                class="timeLineItem"
                data-aos="fade-right"
                data-aos-duration="1200"
                v-on:click="
                  showExp(
                    'Fiverr',
                    'Shopify Developer',
                    'I started with simple Shopify development non custom'
                  )
                "
              >
                <h3>2018</h3>
              </li>
              <li
                class="timeLineItem"
                data-aos="fade-right"
                data-aos-duration="1000"
                v-on:click="
                  showExp(
                    'Fiverr',
                    'Shopify Custom Developer',
                    'After one year I started custom theme development of Shopify and bug fixing'
                  )
                "
              >
                <h3>2019</h3>
              </li>
              <li
                class="timeLineItem"
                data-aos="fade-right"
                data-aos-duration="800"
                v-on:click="
                  showExp(
                    'Vision Bird Technologies',
                    'Front End Developer',
                    'Collaborate with creative and development teams on the execution of ideas.'
                  )
                "
              >
                <h3>2020</h3>
              </li>
              <li
                class="timeLineItem"
                data-aos="fade-right"
                data-aos-duration="1200"
                v-on:click="
                  showExp(
                    'Vision Bird Technologies',
                    'Senior Front End Developer',
                    'Lead of Front End Development department in VBT and started working on JS frameworks and libraries.'
                  )
                "
              >
                <h3>2021</h3>
              </li>
              <li
                class="timeLineItem"
                data-aos="fade-right"
                data-aos-duration="1000"
                v-on:click="
                  showExp(
                    'Mindwhiz PVT LTD',
                    'eCommerce Developer',
                    ' Working as an eCommerce Developer on eCommerce Platforms like Shopify, Wordpres e.t.c. Mostly working as a custom Theme developer.'
                  )
                "
              >
                <h3>2022</h3>
              </li>
              <li
                class="timeLineItem"
                data-aos="fade-right"
                data-aos-duration="1000"
                v-on:click="
                  showExp(
                    'C2 Digital Canada',
                    'Full Stack Shopify Developer',
                    'Working on Shopify Frontend, Backend, APIs Custom Theme Development and Custom App Development'
                  )
                "
              >
                <h3>2023</h3>
              </li>
              <li
                class="timeLineItem"
                data-aos="fade-right"
                data-aos-duration="1000"
                v-on:click="
                  showExp(
                    'C2 Digital Canada',
                    'Full Stack Shopify Developer',
                    'Working on Shopify Frontend, Backend, APIs Custom Theme Development and Custom App Development'
                  )
                "
              >
                <h3>2024</h3>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-6 order-lg-1 order-0">
          <div class="commonnTxtContainer">
            <span class="sideTitle">EXPERIENCE</span>
            <div data-aos="fade-up" data-aos-duration="1000">
              <h2 class="secCommonTitle">
                4+ Years of Experience with <br />
                Many achievements!
              </h2>
            </div>
            <p
              class="secCommonParagraph"
              data-aos="fade-up"
              data-aos-duration="1100"
            >
              I have been developing sites and apps for 4 years and I am expert
              in front end development and eCommerece web apps developments.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "experience",
  data() {
    return {
      componyTitle: "",
      designation: "",
      jobInfo: "",
      activeToolTip: false,
    };
  },
  methods: {
    showExp(comTitle, desig, info) {
      this.componyTitle = comTitle;
      this.designation = desig;
      this.jobInfo = info;
      this.activeToolTip = true;
    },
    closeToolTip() {
      this.activeToolTip = false;
    },
  },
};
</script>