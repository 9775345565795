import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "./plugins/bootstrap-vue";
import App from "./App.vue";
import router from "./router";
import AOS from "aos";
import "aos/dist/aos.css";
import VTooltip from "v-tooltip";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faFontAwesome } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import VueKinesis from "vue-kinesis";
import "./assets/scss/vendors/bootstrap-vue/index.scss";
import "./assets/css/style.css";
library.add(fas);
library.add(faFontAwesome);

/*social icons importing start */
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
library.add(faFacebook);
import { faGithub } from "@fortawesome/free-brands-svg-icons";
library.add(faGithub);
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
library.add(faInstagram);
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
library.add(faLinkedin);
/*social icons importing end */

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.config.productionTip = false;
Vue.use(VueKinesis);
Vue.use(VTooltip);
new Vue({
  router,
  render: (h) => h(App),
  mounted() {
    AOS.init({
      once: true,
    });

  },
}).$mount("#app");
