<template>
  <section id="portfolio">
    <div class="homePortfolioContainer">
      <div class="row">
        <div class="col-lg-12">
          <div class="portfloiInfoArea mainPortfolioInfoTxt" data-aos="zoom-in-up" data-aos-duration="1000">
              <div class="commonnTxtContainer">
                <span class="sideTitle">MY WORKS</span>
                <h2 class="secCommonTitle">
                  See My Works Which Will Amaze You!
                </h2>
                <p class="secCommonParagraph">
                  Here is the list of best quality websites, landing pages, and web apps. Some of these are open source
                  and you can check them on my GitHub account. <span>For an exclusive look at more amazing and mind-blowing
                  projects that I can't display publicly due to client and company policies, feel free to request my
                  portfolio via email.</span>
                </p>
                <div class="tabButtonsList">
                  <div class="tabButtonContainer">
                    <button class="tabBtn activeTab" id="allTab" v-on:click="changePortfolio('all')">
                      All
                    </button>
                  </div>
                  <div class="tabButtonContainer" v-for="(portfolioTypeObj, key) in uniqueProjectType" :key="key">
                    <button class="tabBtn" :id="portfolioTypeObj.portfolioType + 'Tab'" v-on:click="
                      changePortfolio(portfolioTypeObj.portfolioType)
                      ">
                      {{ portfolioTypeObj.portfolioType }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          <div class="portfolioWorkLists" data-aos="zoom-in-up" data-aos-duration="1000">

            <div v-for="(portfolioInfoObj, key) in portfolioInfo" :key="key" class="portfolioInfoBox">
              <div v-if="activeType == 'all' ||
                activeType == portfolioInfoObj.portfolioType
                " class="portfolioItem">
                <a :href="portfolioInfoObj.portfolioUrl" class="portfolioLink" target="_blank">
                  <img v-if="portfolioInfoObj.mediaType === 'img'" :src="portfolioInfoObj.portfolioImg" alt="" />
                  <video v-else-if="portfolioInfoObj.mediaType === 'vid'" :src="portfolioInfoObj.portfolioImg"
                    class="portfolioVid" autoplay muted loop playsinline></video>
                </a>
              </div>
            </div>
          </div>
          <div class="portfolioInfoBox">
            <div class="loadMoreBtn">
              <button v-if="loadBtnNtCliked" v-on:click="loadMoreWork()" class="LoadBtn">
                Load More Work
              </button>
            </div>
            <div v-if="animatedLoader" class="animatedLoader"></div>
            <p v-if="workMessage" class="workMessage">No more work....</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "portfolio",

  data() {
    return {
      activeType: "all",
      animatedLoader: false,
      workMessage: false,
      loadBtnNtCliked: true,
      portfolioInfo: [
      {
          portfolioImg: require("../assets/img/portfolio/alchelyst.mp4"),
          portfolioName: "<h3>Alchelyst</h3>",
          portfolioUrl: "https://alchelyst.ca/",
          portfolioType: "Vue",
          mediaType: "vid",
        },
        {
          portfolioImg: require("../assets/img/portfolio/zimed.mp4"),
          portfolioName: "<h3>Zimed</h3>",
          portfolioUrl: "https://zimedpf.ca",
          portfolioType: "Vue",
          mediaType: "vid",
        },
        {
          portfolioImg: require("../assets/img/portfolio/orderAPI.png"),
          portfolioName: "<h3>Shopify Order API With Node/Express JS</h3>",
          portfolioUrl: "https://github.com/saad2809/Shopify-Order-App",
          portfolioType: "Express",
          mediaType: "img",
        },
        {
          portfolioImg: require("../assets/img/portfolio/customPizza.mp4"),
          portfolioName: "<h3>Custom Pizza</h3>",
          portfolioUrl: "https://mycustompizza.netlify.app/",
          portfolioType: "HTML",
          mediaType: "vid",
        },
        {
          portfolioImg: require("../assets/img/portfolio/neverNapping.png"),
          portfolioName: "<h3>Never Napping</h3>",
          portfolioUrl: "https://www.nevernapping.com/",
          portfolioType: "Vue",
          mediaType: "img",
        },

        {
          portfolioImg: require("../assets/img/portfolio/cloneSR.png"),
          portfolioName: "<h3>Clone of Slider Revolution</h3>",
          portfolioUrl:
            "https://github.com/saadullahsajid/Slide-Revolution-Clone",
          portfolioType: "HTML",
          mediaType: "img",
        },
        {
          portfolioImg: require("../assets/img/portfolio/truff.png"),
          portfolioName: "<h3>Truff</h3>",
          portfolioUrl: "https://truff.com/",
          portfolioType: "Shopify",
          mediaType: "img",
        },

        {
          portfolioImg: require("../assets/img/portfolio/nileCoffe.png"),
          portfolioName: "<h3>Nile Coffee</h3>",
          portfolioUrl: "https://nilecoffee.club/",
          portfolioType: "Shopify",
          mediaType: "img",
        },
        {
          portfolioImg: require("../assets/img/portfolio/carWash.png"),
          portfolioName: "<h3>Alpha Car Washer</h3>",
          portfolioUrl: "https://www.alphacarwashers.pk",
          portfolioType: "Vue",
          mediaType: "img",
        },

        {
          portfolioImg: require("../assets/img/portfolio/etiko.png"),
          portfolioName: "<h3>Etiko</h3>",
          portfolioUrl: "https://etiko.com.au/",
          portfolioType: "Shopify",
          mediaType: "img",
        },
        {
          portfolioImg: require("../assets/img/portfolio/jerdoni.png"),
          portfolioName: "<h3>Jerdoni</h3>",
          portfolioUrl: "https://www.jerdoni.com",
          portfolioType: "Shopify",
          mediaType: "img",
        },

        {
          portfolioImg: require("../assets/img/portfolio/highLand.png"),
          portfolioName: "<h3>High Land</h3>",
          portfolioUrl: "https://www.highlandredstone.com/",
          portfolioType: "Shopify",
          mediaType: "img",
        },
        {
          portfolioImg: require("../assets/img/portfolio/scented.png"),
          portfolioName: "<h3>Scented Sensations</h3>",
          portfolioUrl: "https://scentedsensations.com.au/",
          portfolioType: "Shopify",
          mediaType: "img",
        },
      ],
    };
  },
  computed: {
    // computed property for getting unique Project Type for tab Buttons start

    uniqueProjectType() {
      return this.portfolioInfo.reduce((seed, current) => {
        return Object.assign(seed, {
          [current.portfolioType]: current,
        });
      }, {});
    },

    // computed property for getting unique Project Type for tab Buttons end
  },
  methods: {
    // portfolio change tab function start
    changePortfolio(activePortfolio) {
      this.activeType = activePortfolio;
      var allTabBtns = document.querySelector(".activeTab");
      allTabBtns.classList.remove("activeTab");
      document
        .getElementById(activePortfolio + "Tab")
        .classList.add("activeTab");
    },
    // portfolio change tab function end
    // load more work function start
    loadMoreWork() {
      this.loadBtnNtCliked = !this.loadBtnNtCliked;
      this.animatedLoader = !this.animatedLoader;
      setTimeout(
        function (scope) {
          scope.animatedLoader = !scope.animatedLoader;
          scope.workMessage = !scope.workMessage;
        },
        2000,
        this
      );
    },
    // load more work function start s
  },
};
</script>
