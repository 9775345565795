var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"experience"}},[_c('div',{staticClass:"mainExperienceContainer"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-lg-6 order-lg-0 order-1"},[_c('div',{staticClass:"expereinceTimeLineArea"},[_vm._m(0),_c('div',{staticClass:"custToolTipContainer",class:{ toolTipActive: _vm.activeToolTip }},[_c('button',{staticClass:"closToolTip",on:{"click":_vm.closeToolTip}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'times']}})],1),_c('h2',{staticClass:"componyTitle"},[_vm._v(_vm._s(_vm.componyTitle))]),_c('h3',{staticClass:"designation"},[_vm._v(_vm._s(_vm.designation))]),_c('p',{staticClass:"jobInfo"},[_vm._v(" "+_vm._s(_vm.jobInfo)+" ")])]),_c('ul',{staticClass:"timeLineItemsList"},[_c('li',{staticClass:"timeLineItem",attrs:{"data-aos":"fade-right","data-aos-duration":"1200"},on:{"click":function($event){return _vm.showExp(
                  'Fiverr',
                  'Shopify Developer',
                  'I started with simple Shopify development non custom'
                )}}},[_c('h3',[_vm._v("2018")])]),_c('li',{staticClass:"timeLineItem",attrs:{"data-aos":"fade-right","data-aos-duration":"1000"},on:{"click":function($event){return _vm.showExp(
                  'Fiverr',
                  'Shopify Custom Developer',
                  'After one year I started custom theme development of Shopify and bug fixing'
                )}}},[_c('h3',[_vm._v("2019")])]),_c('li',{staticClass:"timeLineItem",attrs:{"data-aos":"fade-right","data-aos-duration":"800"},on:{"click":function($event){return _vm.showExp(
                  'Vision Bird Technologies',
                  'Front End Developer',
                  'Collaborate with creative and development teams on the execution of ideas.'
                )}}},[_c('h3',[_vm._v("2020")])]),_c('li',{staticClass:"timeLineItem",attrs:{"data-aos":"fade-right","data-aos-duration":"1200"},on:{"click":function($event){return _vm.showExp(
                  'Vision Bird Technologies',
                  'Senior Front End Developer',
                  'Lead of Front End Development department in VBT and started working on JS frameworks and libraries.'
                )}}},[_c('h3',[_vm._v("2021")])]),_c('li',{staticClass:"timeLineItem",attrs:{"data-aos":"fade-right","data-aos-duration":"1000"},on:{"click":function($event){return _vm.showExp(
                  'Mindwhiz PVT LTD',
                  'eCommerce Developer',
                  ' Working as an eCommerce Developer on eCommerce Platforms like Shopify, Wordpres e.t.c. Mostly working as a custom Theme developer.'
                )}}},[_c('h3',[_vm._v("2022")])]),_c('li',{staticClass:"timeLineItem",attrs:{"data-aos":"fade-right","data-aos-duration":"1000"},on:{"click":function($event){return _vm.showExp(
                  'C2 Digital Canada',
                  'Full Stack Shopify Developer',
                  'Working on Shopify Frontend, Backend, APIs Custom Theme Development and Custom App Development'
                )}}},[_c('h3',[_vm._v("2023")])]),_c('li',{staticClass:"timeLineItem",attrs:{"data-aos":"fade-right","data-aos-duration":"1000"},on:{"click":function($event){return _vm.showExp(
                  'C2 Digital Canada',
                  'Full Stack Shopify Developer',
                  'Working on Shopify Frontend, Backend, APIs Custom Theme Development and Custom App Development'
                )}}},[_c('h3',[_vm._v("2024")])])])])]),_vm._m(1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"timepath"},[_c('span',{staticClass:"line"}),_c('span',{staticClass:"semicircle"}),_c('span',{staticClass:"line"}),_c('span',{staticClass:"semicircle"}),_c('span',{staticClass:"line"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-lg-6 order-lg-1 order-0"},[_c('div',{staticClass:"commonnTxtContainer"},[_c('span',{staticClass:"sideTitle"},[_vm._v("EXPERIENCE")]),_c('div',{attrs:{"data-aos":"fade-up","data-aos-duration":"1000"}},[_c('h2',{staticClass:"secCommonTitle"},[_vm._v(" 4+ Years of Experience with "),_c('br'),_vm._v(" Many achievements! ")])]),_c('p',{staticClass:"secCommonParagraph",attrs:{"data-aos":"fade-up","data-aos-duration":"1100"}},[_vm._v(" I have been developing sites and apps for 4 years and I am expert in front end development and eCommerece web apps developments. ")])])])}]

export { render, staticRenderFns }