<template>
  <div class="footer">
    <div class="container">
      <div class="footerRow">
        <div class="brand">
          <h1 class="logo">
            <a
              href="/"
              aria-current="page"
              class="activeLink router-link-active"
            >
              Prof<span>.</span></a
            >
          </h1>
        </div>
        <p class="ftInfo">
          © 2021 - 2022 
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "footer",
};
</script>