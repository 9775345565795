<template>
  <section id="skills">
    <div class="SkillsContainer">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="commonnTxtContainer">
            <span class="sideTitle">My Skills</span>
            <div data-aos="fade-up" data-aos-duration="1000">
              <h2 class="secCommonTitle">
                Programming and <br />
                Others Skills?
              </h2>
            </div>

            <p
              class="secCommonParagraph"
              data-aos="fade-up"
              data-aos-duration="1100"
            >
              I am expert in web development and my major skills are in front
              end tool and technologies also have good knowledge in UI/UX
              designing.
            </p>

            <div
              class="toggleTabBtnContainer"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <label v-bind:class="{ checkedClass: showTools }" class="custSwitcher hovercursor">
                <input
                  id="toggleSkillTool"
                  v-on:change="toggleTools()"
                  type="checkbox"
                  class="hovercursor"
                />
                <span class="switchSlider"></span>
                <span class="skillBtn">Skills</span>
                <span class="toolBtn">Tools</span>
              </label>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="skillsIconsContainer">
            <ul v-if="showTools" id="skillsList">
              <li
                data-aos="fade-up"
                data-aos-duration="700"
                v-tooltip="'HTML 5'"
              >
                <div class="skillIconBox">
                  <img src="@/assets/img/html5.png" alt="" />
                </div>
              </li>
              <li data-aos="fade-up" data-aos-duration="800" v-tooltip="'CSS'">
                <div class="skillIconBox">
                  <img src="@/assets/img/css3.png" alt="" />
                </div>
              </li>
              <li
                data-aos="fade-up"
                data-aos-duration="900"
                v-tooltip="'Javascript'"
              >
                <div class="skillIconBox">
                  <img src="@/assets/img/javascript.png" alt="" />
                </div>
              </li>
              <li
                data-aos="fade-up"
                data-aos-duration="1000"
                v-tooltip="'Bootstrap'"
              >
                <div class="skillIconBox">
                  <img src="@/assets/img/bootstrap.png" alt="" />
                </div>
              </li>
              <li
                data-aos="fade-up"
                data-aos-duration="1100"
                v-tooltip="'jQuery'"
              >
                <div class="skillIconBox">
                  <img src="@/assets/img/jquery.png" alt="" />
                </div>
              </li>
              <li
                data-aos="fade-up"
                data-aos-duration="1200"
                v-tooltip="'SASS'"
              >
                <div class="skillIconBox">
                  <img src="@/assets/img/sass.png" alt="" />
                </div>
              </li>
              <li
                data-aos="fade-up"
                data-aos-duration="1300"
                v-tooltip="'Vue Js'"
              >
                <div class="skillIconBox">
                  <img src="@/assets/img/vuejs.png" alt="" />
                </div>
              </li>
              <li
                data-aos="fade-up"
                data-aos-duration="1400"
                v-tooltip="'Vuetify'"
              >
                <div class="skillIconBox">
                  <img src="@/assets/img/vuetify.png" alt="" />
                </div>
              </li>
              <li
                data-aos="fade-up"
                data-aos-duration="1350"
                v-tooltip="'Nuxt Js'"
              >
                <div class="skillIconBox">
                  <img src="@/assets/img/nuxtJs.png" alt="" />
                </div>
              </li>
              <li
                data-aos="fade-up"
                data-aos-duration="1500"
                v-tooltip="'Node JS'"
              >
                <div class="skillIconBox">
                  <img src="@/assets/img/node-js.png" alt="" />
                </div>
              </li>
              <li
                data-aos="fade-up"
                data-aos-duration="1500"
                v-tooltip="'Express JS'"
              >
                <div class="skillIconBox">
                  <img src="@/assets/img/express.png" alt="" />
                </div>
              </li>
              <li
                data-aos="fade-up"
                data-aos-duration="1600"
                v-tooltip="'Firebase'"
              >
                <div class="skillIconBox">
                  <img src="@/assets/img/firebase.png" alt="" />
                </div>
              </li>
              <li
                data-aos="fade-up"
                data-aos-duration="1700"
                v-tooltip="'Shopify'"
              >
                <div class="skillIconBox">
                  <img src="@/assets/img/shopify.png" alt="" />
                </div>
              </li>
              <li
                data-aos="fade-up"
                data-aos-duration="1800"
                v-tooltip="'Wordpress'"
              >
                <div class="skillIconBox">
                  <img src="@/assets/img/wordpress.png" alt="" />
                </div>
              </li>
            </ul>
            <ul v-else id="toolsList">
              <li
                data-aos="fade-up"
                data-aos-duration="700"
                v-tooltip="'VS Code'"
              >
                <div class="skillIconBox">
                  <img src="@/assets/img/visual-studio-code.png" alt="" />
                </div>
              </li>
              <li data-aos="fade-up" data-aos-duration="800" v-tooltip="'Git'">
                <div class="skillIconBox">
                  <img src="@/assets/img/git.png" alt="" />
                </div>
              </li>
              <li data-aos="fade-up" data-aos-duration="900" v-tooltip="'NPM'">
                <div class="skillIconBox">
                  <img src="@/assets/img/npm.png" alt="" />
                </div>
              </li>
              <li
                data-aos="fade-up"
                data-aos-duration="1000"
                v-tooltip="'Adobe Photoshop'"
              >
                <div class="skillIconBox">
                  <img src="@/assets/img/adobe-photoshop.png" alt="" />
                </div>
              </li>
              <li
                data-aos="fade-up"
                data-aos-duration="1100"
                v-tooltip="'Adobe Illustrator'"
              >
                <div class="skillIconBox">
                  <img src="@/assets/img/adobe-illustrator.png" alt="" />
                </div>
              </li>
              <li
                data-aos="fade-up"
                data-aos-duration="1200"
                v-tooltip="'Adobe XD'"
              >
                <div class="skillIconBox">
                  <img src="@/assets/img/adobe-xd.png" alt="" />
                </div>
              </li>
              <li
                data-aos="fade-up"
                data-aos-duration="1300"
                v-tooltip="'Figma'"
              >
                <div class="skillIconBox">
                  <img src="@/assets/img/figma.png" alt="" />
                </div>
              </li>
              <li
                data-aos="fade-up"
                data-aos-duration="1400"
                v-tooltip="'Slack'"
              >
                <div class="skillIconBox">
                  <img src="@/assets/img/slack.png" alt="" />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "skills",
  data() {
    return {
      showTools: true,
    };
  },
  methods: {
    toggleTools() {
      this.showTools = !this.showTools;
    },
  },
};
</script>