<template>
  <section id="about">
    <div class="aboutMeContainer">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div data-aos="zoom-in" data-aos-duration="1500" class="aboutImgContainer">
            <div class="imgLayer">
              <img src="@/assets/img/saadAbout.png" alt="" />
            </div>
            <div class="imgLayer2">
              <img src="@/assets/img/saadAbout.png" alt="" />
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="commonnTxtContainer" data-aos="zoom-in" data-aos-duration="1200">
            <span class="sideTitle">About Me</span>
            <h2 class="secCommonTitle">
              Need a Creative Product? <br />
              I can Help You!
            </h2>
            <p class="secCommonParagraph">
              Hi! I’m Saad Ullah Sajid, a Software Engineer and Web Developer with over 4 years of experience. I graduated
              with a BS in Information Technology from the University of Gujrat in 2020. My expertise spans frontend
              development with HTML, CSS, JavaScript, Vue.js, and backend technologies such as Node.js, Express.js, and
              Firebase. I am also experienced in Shopify and WordPress development. <br/>

              I enjoy transforming ideas into reality using creative solutions, and I have worked on various web platforms
              both independently and as part of creative teams. If you’re looking for high-quality web apps, websites, or
              any web solutions, I am available and open to work with you.
            </p>
            <div class="aboutBtnGrp">
              <a href="#contact" class="btn solidBtn"> Hire Me </a>
              <a href="documents/Saad-CV.pdf" target="_blank" download class="btn borderBtn"> Download CV </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "about",
};
</script>