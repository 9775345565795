<template>
  <section id="contact">
    <div class="contactContainer">
      <div class="row">
        <div class="col-lg-6">
          <div class="commonnTxtContainer">
            <span class="sideTitle">CONTACT</span>
            <h2 class="secCommonTitle">Get in touch</h2>
            <p class="secCommonParagraph">
              Let me know how I can help? Fill out the form and I’ll be in touch
              as soon as possible.
            </p>
            <div class="contactDetail">
              <ul class="contactInfo">
                <li>
                  <span class="contactIcon">
                    <font-awesome-icon :icon="['fas', 'map-marker-alt']" />
                  </span>
                  <div><strong>Address:</strong> Lahore, Punjab, Pakistan</div>
                </li>
                <li>
                  <span class="contactIcon">
                    <font-awesome-icon :icon="['fas', 'mobile-alt']" />
                  </span>
                  <div>
                    <strong>Phone:</strong>
                    <a href="tel:+923147830418"> +923147830418 </a>
                  </div>
                </li>
                <li>
                  <span class="contactIcon">
                    <font-awesome-icon :icon="['fas', 'envelope']" />
                  </span>
                  <div>
                    <strong>Email:</strong>
                    <a href="mailto:saadullahsajid28091998@gmail.com">
                      saadullahsajid28091998.com
                    </a>
                  </div>
                </li>
              </ul>
              <ul class="ftSocial">
                <li>
                  <a
                    target="_blank"
                    href="https://www.facebook.com/saadullah.sajid.338/"
                  >
                    <font-awesome-icon :icon="['fab', 'facebook']" />
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://www.instagram.com/saad.s2809"
                  >
                    <font-awesome-icon :icon="['fab', 'instagram']" />
                  </a>
                </li>

                <li>
                  <a
                    target="_blank"
                    href="https://pk.linkedin.com/in/saad-ullah-sajid-301173166"
                  >
                    <font-awesome-icon :icon="['fab', 'linkedin']" />
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://github.com/saadullahsajid">
                    <font-awesome-icon :icon="['fab', 'github']" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="contactFormContainer">
            <form ref="form" @submit.prevent="sendEmail" class="contactForm">
              <div class="formGroup">
                <label>Name</label>
                <div class="iconWithInput">
                  <input
                    v-model="form.nameBox"
                    id="namebox"
                    type="text"
                    name="user_name"
                    placeholder="e.g. John Doe"
                    required=""
                  />
                  <span>
                    <font-awesome-icon :icon="['fa', 'user']" />
                  </span>
                </div>
              </div>
              <div class="formGroup">
                <label>Email</label>
                <div class="iconWithInput">
                  <input
                    v-model="form.emailbox"
                    id="emailbox"
                    type="email"
                    name="user_email"
                    placeholder="e.g. john@gmail.com"
                    required=""
                  />
                  <span>
                    <font-awesome-icon :icon="['fas', 'envelope']" />
                  </span>
                </div>
              </div>
              <div class="formGroup">
                <label>Phone<span>(optional)</span></label>
                <div class="iconWithInput">
                  <input
                    v-model="form.phoneBox"
                    id="phoneBox"
                    type="tel"
                    name="user_phone"
                    inputmode="tel"
                    placeholder="e.g. 090078601"
                  />
                  <span>
                    <font-awesome-icon :icon="['fa', 'phone']" />
                  </span>
                </div>
              </div>
              <div class="formGroup">
                <label>Message</label>
                <div class="iconWithInput">
                  <textarea
                    v-model="form.messagebox"
                    id="messagebox"
                    name="message"
                    placeholder="Write message..."
                    required=""
                    rows="4"
                  ></textarea>
                  <span class="textAreaIcon">
                    <font-awesome-icon :icon="['fas', 'comment']" />
                  </span>
                </div>
              </div>
              <div class="formGroup">
                <p
                  v-if="successMsg"
                  class="successMsg formMsg bg-success text-white"
                >
                  Message sent successfully. I will get back to you shortly!
                </p>
                <p
                  v-if="failedMsg"
                  class="errorMsg formMsg bg-danger text-white"
                >
                  There was an error trying to send your message. Please try
                  again later.
                </p>
              </div>
              <div class="formGroup">
                <button class="btn submitBtn" type="submit">Send</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import emailjs from "@emailjs/browser";
export default {
  name: "contact",
  data() {
    return {
      form: {
        nameBox: "",
        emailBox: "",
        phoneBox: "",
        messagebox: "",
      },
      successMsg: false,
      failedMsg: false,
    };
  },
  methods: {
    sendEmail() {
      this.successMsg = false;
      this.failedMsg = false;
      emailjs
        .sendForm(
          "service_jh4chdr",
          "template_4xnvjhm",
          this.$refs.form,
          "l9RtflLd4cRs8sJrh"
        )
        .then(
          (result) => {
            this.$data.form.nameBox = "";
            this.$data.form.emailbox = "";
            this.$data.form.phoneBox = "";
            this.$data.form.messagebox = "";
            this.failedMsg = false;
            console.log("SUCCESS!", result.text);
            this.successMsg = true;
          },
          (error) => {
            this.$data.form.nameBox = "";
            this.$data.form.emailbox = "";
            this.$data.form.phoneBox = "";
            this.$data.form.messagebox = "";
            this.successMsg = false;
            console.log("FAILED...", error.text);
            this.failedMsg = true;
          }
        );
    },
  },
};
</script>

